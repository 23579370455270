export const Subjects = [
  'Arabic',
  'Biology',
  'Business Related',
  'Cantonese',
  'Chemistry',
  'Citizenship',
  'Classical Civilisation',
  'Combined Science',
  'Computer Studies / I.T.',
  'Craft and Design',
  'Drama',
  'Economics',
  'Electronics / Electronic Engineering',
  'English',
  'English (Foreign Language)',
  'Entrance Exams',
  'Entrepreneurship',
  'French',
  'Geography',
  'Geology',
  'German',
  'Government and Politics',
  'Greek (Ancient)',
  'handwriting and calligraphy',
  'Health / Social Care',
  'History',
  'History Of Art',
  'Italian',
  'Japanese',
  'Latin',
  'Law',
  'Mandarin',
  'Maths',
  'Mechanical Engineering',
  'Media / Communication Studies',
  'Medicine',
  'Music',
  'PE / Sport Science',
  'Personal Development',
  'Philosophy / Critical Thinking',
  'Physics',
  'Polish',
  'Psychology',
  'Religious Studies',
  'Russian',
  'Sociology',
  'Spanish',
  'Special Needs',
  'Study Skills',
  'Urdu',
  'Welsh',
];

export const Levels = [
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'GCSE', value: 'gsce' },
  { label: 'A Level', value: 'a_level' },
  { label: 'Higher Education', value: 'higher_education' },
  { label: 'Casual Learner', value: 'casual_learner' },
];
