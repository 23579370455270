import { useState } from 'react';
import { useRouter } from 'next/router';

import { ROUTES } from 'constants/routes';

import styles from './SearchBar.module.css';
import { Levels, Subjects } from '../../utils/home-data';
import { isValidUKPostcode } from 'utils/validations';

const SearchBar = () => {
  const router = useRouter();

  const [searchQuery, setSearchQuery] = useState({
    subject: '',
    graduationLevel: '',
    postCode: '',
  });

  const isPostCodeInvalid = !isValidUKPostcode(searchQuery.postCode);
  const isSearchQueryValid =
    !isPostCodeInvalid && searchQuery.subject && searchQuery.graduationLevel;

  const updateQuery = (key: keyof typeof searchQuery, value: string) =>
    setSearchQuery((prevState) => ({ ...prevState, [key]: value }));

  const handleSearch = () => {
    router.push({
      pathname: ROUTES.search,
      query: searchQuery,
    });
  };

  const capitalizeWord = (word: string) => {
    return word
      .split('_')
      .map((w) => {
        return w.charAt(0).toUpperCase() + w.slice(1);
      })
      .join(' ');
  };

  return (
    <div className={styles['section-4']}>
      <div className={styles['div-block']}>
        <div
          id="filter-search"
          className={`${styles['form-block']} ${styles['w-form']} ${styles['form']}`}
        >
          {/* ----- Subject ----- */}
          <div className={styles['select-container']}>
            <select
              title="Select your subject"
              id="field"
              onChange={(e) => updateQuery('subject', e.target.value)}
              name="field"
              data-name="Field"
              className={`${styles['select-field-2']} ${styles['w-select']}`}
            >
              <option value="">Select your subject</option>
              {Subjects.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
            <i className="fas  fa-bars"></i>
          </div>

          {/* ----- Level ----- */}
          <div className={styles['select-container']}>
            <select
              title="Select your level"
              id="field-3"
              onChange={(e) => updateQuery('graduationLevel', e.target.value)}
              name="field-3"
              data-name="Field 3"
              className={`${styles['select-field-2']} ${styles['w-select']}`}
            >
              <option value="">Select your level</option>
              {Levels.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <i className="fas fa-trophy"></i>
          </div>

          {/* ----- PostCode ----- */}
          <div className={styles['select-container']}>
            <input
              className={`${styles['text-field']} ${styles['w-input']} ${
                isPostCodeInvalid &&
                !!searchQuery.postCode &&
                styles['w-input-error']
              }`}
              maxLength={8}
              name="postCode"
              data-name="Post Code"
              placeholder="Post Code"
              type="text"
              id="postCode"
              onChange={(e) => updateQuery('postCode', e.target.value)}
              value={searchQuery.postCode}
              required
            />
            <i className="fas fa-map-marker-alt"></i>
          </div>
          <button
            title="Search"
            onClick={handleSearch}
            data-wait="Please wait..."
            className={`${styles['submit-button']} ${styles['w-button']}`}
            disabled={!isSearchQueryValid}
          >
            Search
          </button>
          {/* <div className={styles['w-form-done']}>
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className={styles['w-form-fail']}>
            <div>Oops! Something went wrong while submitting the form.</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
