import React, { useState } from 'react';
import styles from './blogSection.module.css';
import image2 from '/blogSection/image2.jpeg';
import image3 from '/blogSection/image3.jpeg';
import Image from 'next/image';
import ButtonBig from 'components/Button/Button';
import { blogPosts, IBlogPost } from './blogData';
import WrapperLink from 'components/WrapperLink';

const BlogPost = ({ post }: { post: IBlogPost }) => (
  <div className={`${styles['post-featured-item']} ${styles['w-dyn-item']}`}>
    <WrapperLink
      href={post.url}
      className={`${styles.card} ${styles['post-featured']} ${styles['w-inline-block']}`}
    >
      <div
        className={`${styles['image-wrapper']} ${styles['card-post-featured']}`}
      >
        <div className={`${styles.image} ${styles['card-post-featured']}`}>
          <Image
            src={post.image}
            width={270}
            height={182}
            sizes="100%"
            alt={post.title}
          />
        </div>
      </div>
      <div className={`${styles['card-post-featured-content']}`}>
        <h4 className={`${styles.title} ${styles['card-post-featured']}`}>
          {post.title}
        </h4>
      </div>
    </WrapperLink>
  </div>
);

const blogSection = () => {
  return (
    <div className={`${styles.section} ${styles['blog-section']}`}>
      <div
        className={`${styles['container-default']} ${styles['w-container']}`}
      >
        <div className={`${styles['content-top']} ${styles['blog-section']}`}>
          <div
            className={`${styles['split-content']} ${styles['blog-section-left']}`}
          >
            <h2 className={`${styles.title} ${styles['blog-section']}`}>
              Resources &amp; News
            </h2>
          </div>
          <div
            className={`${styles['split-content']} ${styles['blog-section-right']}`}
          >
            <ButtonBig
              className="button-secondary w-button"
              title="Browse Blog"
              href="/blog"
            />
          </div>
        </div>
        <div
          className={`${styles['w-layout-grid']} ${styles['blog-section-grid']}`}
        >
          <div className={`w-dyn-list`}>
            <div role="list" className={'w-dyn-items'}>
              <div
                role="listitem"
                className={`${styles['post-featured-first-item']} ${styles['w-dyn-item']}`}
              >
                <WrapperLink
                  href="/blog/find-online-tutoring-guide"
                  className={`${styles.card} ${styles['post-featured-first']} ${styles['w-inline-block']}`}
                >
                  <div
                    className={`${styles['image-wrapper']} ${styles['card-post-featured-first']}`}
                  >
                    <div
                      className={`${styles.image} ${styles['card-post-featured-first']}`}
                    >
                      <Image
                        src={'/blogSection/image1.jpeg'}
                        width={567}
                        height={400}
                        alt="Find the Perfect Online Tutor: Your Path to Academic Excellence"
                      />
                    </div>
                    <div
                      className={`${styles['card-post-featured-first-category-wrapper']}`}
                    >
                      <div
                        className={`${styles.image} ${styles['card-post-featured-first-category-icon']}`}
                      >
                        <Image
                          src={'/blogSection/study.svg'}
                          alt="Study"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div>Study</div>
                    </div>
                  </div>
                  <div
                    className={`${styles['card-post-featured-first-content']}`}
                  >
                    <div
                      className={`${styles['card-post-featured-first-date']}`}
                    >
                      September 30, 2023
                    </div>
                    <h3
                      className={`${styles.title} ${styles['card-post-featured-first']}`}
                    >
                      Find the Perfect Online Tutor: Your Path to Academic
                      Excellence
                    </h3>
                  </div>
                </WrapperLink>
              </div>
            </div>
          </div>
          <div className={`${styles['w-dyn-list']}`}>
            <div
              className={`${styles['post-featured-sidebar-grid']} ${styles['w-dyn-items']}`}
            >
              {blogPosts.map((post) => {
                return <BlogPost key={post.title} post={post} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default blogSection;
