import Image from 'next/image';
import React from 'react';
import styles from './perks.module.css';
import { motion } from 'framer-motion';

const PerksSection = () => {
  const steps = [
    {
      id: 1,
      imageUrl: '/perks/findatutor.svg',
      heading: '1. Find a Tutor',
      desc: "Explore our extensive database, featuring 100's of tutors, to discover the match for your unique learning requirements.",
    },
    {
      id: 2,
      imageUrl: '/perks/learn.svg',
      heading: '2. Learn',
      desc: ' Choose your schedule, collaborate with your tutor, and empower  learning journey with expert guidance.',
    },
    {
      id: 3,
      imageUrl: '/perks/succeed.svg',
      heading: '3. Succeed',
      desc: 'Your tutor will help you achieve set targets, pass specific and work with you to find academic success.',
    },
  ];

  return (
    <div
      id="Perks"
      className={`${styles.section} ${styles['bg-neutral-700']} ${styles.perks}`}
    >
      <div
        className={`${styles['container-default']} ${styles['w-container']}`}
      >
        <div className={styles['perks-wrapper']}>
          <h2 className={`${styles.title} ${styles.perks}`}>
            How Vital Educators works
          </h2>
          <div className={`${styles['w-layout-grid']} ${styles['perks-grid']}`}>
            {steps?.map((step, index) => (
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3, delay: index * 0.2 }}
                viewport={{ once: true }}
                key={step.id}
                className={styles['perk-wrapper']}
              >
                <div className={`${styles.image} ${styles.perk}`}>
                  <Image
                    src={step.imageUrl}
                    alt="Perks - Education "
                    width={233}
                    height={233}
                  />
                </div>
                <div className={styles['perk-content']}>
                  <h3 className={`${styles.title} ${styles.perk}`}>
                    {step.heading}
                  </h3>
                  <p className={`${styles.paragraph} ${styles.perk}`}>
                    {step.desc}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      <div className={`${styles.bg} ${styles['arrow-wrapper']}`}>
        <motion.div
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className={`${styles.image} ${styles['bg-arrow']}`}
        >
          <Image width={77} height={206} src={'/curved-arrow.svg'} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default PerksSection;
