import React from 'react';
import styles from './aboutSection.module.css';
import Image from 'next/image';
import { motion } from 'framer-motion';
import FadeInWhenVisible from 'components/FadeInWhenVisible/FadeInWhenVisible';

const AboutSection = () => {
  const details = [
    {
      heading: 'Expert teachers',
      iconSrc: '/aboutSection/bag.svg',
      desc: "Educated at the world’s top universities and institutions, our tutors are well equipped to help students achieve their desired grades. Our high client satisfaction gives you the guarantee you'll find tutors who will lift that grade, raise confidence and help you, or your child, discover the joy of learning.",
    },
    {
      heading: 'Free exam materials',
      iconSrc: '/aboutSection/free.svg',
      desc: 'For extra support, we provide free, detailed exam-related content and practice papers for GCSEs and A-levels.',
    },
    {
      heading: 'Career coaching',
      iconSrc: '/aboutSection/people.svg',
      desc: ' We provide career coaching through our podcast. Filled with real-life practical advice, we aim to provide valuable insights into the lives of working professionals of our society to enable students to plan the next steps in their lives.',
    },
  ];

  return (
    <div className={styles.section}>
      <motion.div
        initial={{
          opacity: 0,
          y: -100,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{ duration: 0.1, delay: 0.3 }}
        viewport={{ once: true }}
        className={`${styles['container-small-550px']} ${styles['home-about']}`}
      >
        <h2 className={styles.heading}>About Vital Educators</h2>
        <p className={`${styles['paragraph-2']}`}>
          Vital Educators was established to empower young individuals in our
          society, by providing easy access to expert coaches and tutors, who
          can guide students during their educational journey.
        </p>
      </motion.div>
      <div
        className={`${styles['container-default']} ${styles['w-container']}`}
      >
        <div className={styles['home-about-wrapper']}>
          <div className={`${styles['image-wrapper']} ${styles['home-about']}`}>
            <FadeInWhenVisible>
              <div className={`${styles.image} ${styles['home-about']}`}>
                <Image
                  src={'/aboutSection/teacher.png'}
                  width={580}
                  height={610}
                />
              </div>{' '}
            </FadeInWhenVisible>
          </div>
          <div
            className={`${styles['split-content']} ${styles['home-about-content']}`}
          >
            {details.map((detail, i) => Details(i, detail))}
          </div>
        </div>
      </div>
    </div>
  );

  function Details(
    i: number,
    detail: { heading: string; iconSrc: string; desc: string }
  ): JSX.Element {
    return (
      <motion.div
        initial={{
          opacity: 0,
          y: -100,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{ duration: 0.3, delay: 0.3 * i, ease: 'easeInOut' }}
        viewport={{ once: true }}
        key={i}
        className={styles['home-about-details']}
      >
        <div className={`${styles.image} ${styles['icon-home-about']}`}>
          <Image src={detail.iconSrc} width={60} height={60} alt="Icon " />
        </div>
        <div className={`${styles['home-about-details-content']}`}>
          <h3
            className={`${styles.title} ${styles['home-about-details-title']}`}
          >
            {detail.heading}
          </h3>
          <p
            className={`${styles.paragraph} ${styles['home-about-details-paragraph']}`}
          >
            {detail.desc}
          </p>
        </div>
      </motion.div>
    );
  }
};

export default AboutSection;
