export const ROUTES = {
  root: '/',
  search: '/search',
  freeResources: '/free-resources',
  blog: '/blog',
  about: '/about',
  tutorProfile: `/tutor/profile/[id]`,
  studentLogin: '/student/login',
  studentSignup: '/student/signup',
  tutorLogin: '/tutor/login',
  tutorSignup: '/tutor/signup',
};
