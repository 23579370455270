import Image from 'next/image';
import React from 'react';
import styles from './Features.module.css';
import ButtonBig from 'components/Button/Button';
import FadeInWhenVisible from 'components/FadeInWhenVisible/FadeInWhenVisible';
import { motion } from 'framer-motion';
const FeaturesSection = () => {
  return (
    <div className={`${styles.section} ${styles['featured-teacher']}`}>
      <div
        className={`${styles['container-default']} ${styles['w-container']}`}
      >
        <div className={styles['featured-teacher-wrapper']}>
          <motion.div
            initial={{
              y: 100,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              opacity: 1,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.3, ease: 'easeInOut', delay: 0.2 }}
            className={`${styles['split-content']} ${styles['featured-teacher-left']}`}
          >
            <h2 className={styles['heading-2']}>
              Subjects taught by
              <br />
              subject experts.
            </h2>
            <p className={`${styles.paragraph} ${styles['featured-teacher']}`}>
              Study subjects taught by expert teachers or industry professionals
            </p>
            <div className={styles['_2-buttons']}>
              <ButtonBig
                className="button-primary-header button-2-buttons w-button"
                title="BROWSE TEACHERS"
                href="/about#Teachers"
              />
              <ButtonBig
                className="button-secondary button-2-buttons w-button"
                title="BECOME A TEACHER"
                href="/tutor/signup"
              />
            </div>
          </motion.div>

          <div
            className={`${styles['split-content']} ${styles['featured-teacher-right']}`}
          >
            <div
              className={`${styles['image-wrapper']} ${styles['featured-teacher']}`}
            >
              <FadeInWhenVisible>
                <div
                  className={`${styles.image} ${styles['featured-teacher']}`}
                >
                  <Image
                    src={'/featuresSection/teacher.jpg'}
                    width={556}
                    height={370}
                    alt="featured-teacher"
                  />
                </div>
              </FadeInWhenVisible>
            </div>
            <FadeInWhenVisible>
              <div className={`${styles.card} ${styles['featured-teacher']}`}>
                <div className={styles['card-featured-teacher-badge']}>
                  Teacher Appreciation
                </div>
                <h3
                  className={`${styles.title} ${styles['card-featured-teacher']}`}
                >
                  "Helped me in the last few months in chemistry, Tutor has
                  taught me very well and I have gotten an A* in my chemistry
                  mocks, Thank you Vital Educators"
                </h3>
                <div className={styles['card-featured-teacher-about']}>
                  <div className={styles['card-featured-teacher-name']}>
                    Sophie M
                  </div>
                  <div className={styles['card-featured-teacher-rol']}>
                    Chemistry Student
                  </div>
                </div>
              </div>
            </FadeInWhenVisible>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
