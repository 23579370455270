import React, { useEffect, useState, useRef } from 'react';
import styles from './HomeHero.module.css';
import Image from 'next/image';
import ButtonBig from 'components/Button/Button';
import { motion, useTransform, useScroll, useSpring } from 'framer-motion';
import FadeInWhenVisible from 'components/FadeInWhenVisible/FadeInWhenVisible';
import { ROUTES } from 'constants/routes';

const HeroSection = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 1', '1 0'],
  });
  const scaleForShape1 = useTransform(scrollYProgress, [0, 1], [1, 150]);
  const yForShape1 = useSpring(scaleForShape1);
  const scaleForShape2 = useTransform(scrollYProgress, [0, 1], [0, 100]);
  const yForShape2 = useSpring(scaleForShape2);

  // for images
  const { scrollYProgress: scrollYProgressImage } = useScroll({
    target: ref,
    offset: ['0 1', '1 1'],
  });
  const scaleForImageOpacity = useTransform(
    scrollYProgressImage,
    [0, 0.3],
    [0, 1]
  );
  const yForImageOpacity = useSpring(scaleForImageOpacity);

  const scaleForImageTranslateY = useTransform(
    scrollYProgressImage,
    [0, 0.3],
    [0, 100]
  );

  const yForImageTranslateY = useSpring(scaleForImageTranslateY);

  const scaleForImageRotateZ = useTransform(
    scrollYProgressImage,
    [0, 0.3],
    [20, 0]
  );
  const yForImageRotate = useSpring(scaleForImageRotateZ);

  const scaleForImageScaleXY = useTransform(
    scrollYProgressImage,
    [0, 0.3],
    [1.2, 1]
  );
  const yForImageScaleXY = useSpring(scaleForImageScaleXY);

  return (
    <div ref={ref} className={` ${styles.section} ${styles['home-hero']}`}>
      <div className={`${styles['container-default']} w-container`}>
        <div className={styles['home-hero-wrapper']}>
          <motion.div
            initial={{
              y: 100,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              opacity: 1,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.3, ease: 'easeInOut', delay: 0.3 }}
            className={`${styles['split-content']} ${styles['home-hero-content']}`}
          >
            <h1 className={`${styles.title} ${styles['home-hero']}`}>
              <span className={styles['text-span']}>Expert Tutors:</span> <br />
              Improving Grades,
              <br />
              Increasing Confidence.
            </h1>
            <p className={`${styles.paragraph} ${styles['home-hero']}`}>
              Vital Educators connects you with the best tutors for all levels
              of education. Our high client satisfaction gives you the guarantee
              you'll find tutors who will lift that grade, raise confidence and
              help you, or your child, discover the joy of learning.
            </p>
            <div className={styles['_2-buttons']}>
              <ButtonBig
                className={'button-primary-header button-2-buttons w-button'}
                title="I need tutoring"
                href={ROUTES.studentSignup}
              />

              <ButtonBig
                className={'button-secondary button-2-buttons w-button'}
                title="I am a tutor"
                href={ROUTES.tutorSignup}
              />
            </div>
          </motion.div>
          <motion.div
            style={{
              opacity: yForImageOpacity,

              translateY: `${yForImageTranslateY.get()}%`,
              rotateZ: yForImageRotate,
            }}
            className={`${styles['image-wrapper']} ${styles['home-hero']} `}
          >
            <Image
              width={568}
              height={852}
              src={'/hero/homehero.jpg'}
              alt=""
              className={`${styles.image} ${styles['home-hero']}`}
            />
          </motion.div>
          <motion.div
            style={{ y: yForShape1 }}
            className={`${styles.bg} ${styles['home-hero-shape-1']}`}
          ></motion.div>
          <motion.div
            style={{ y: yForShape2 }}
            className={`${styles.bg} ${styles['home-hero-shape-2']}`}
          ></motion.div>
        </div>
      </div>
      <div className={`${styles.bg} ${styles['home-hero']}`}></div>
    </div>
  );
};

export default HeroSection;
