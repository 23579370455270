import { useState } from 'react';
import Footer from '../components/footer/Footer';
import SearchBar from '../components/home/SearchBar';
import Navbar from '../components/navbar';
import FeaturedTeacherSection from 'components/featuredTeacherSection';
import HeroSection from 'components/heroSection';
import StorySection from 'components/storySection';
import PerksSection from 'components/perksSection';
import FeaturesSection from 'components/featuresSection';
import CtaSection from 'components/ctaSection';
import AboutSection from 'components/aboutSection';
import BlogSection from 'components/blogSection';
import NewsletterSection from 'components/newsletterSection';

import { motion } from 'framer-motion';

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.9 }}
      style={{
        overflowX: 'hidden',
      }}
    >
      <Navbar />
      <div>
        <SearchBar />
        <HeroSection />
        <StorySection />
        <PerksSection />
        <FeaturesSection />
        <CtaSection />
        <AboutSection />

        <div className="container-default w-container">
          <div className="divider"></div>
        </div>
        <BlogSection />
        <NewsletterSection />
      </div>
      <Footer />
    </motion.div>
  );
};

export default Home;
