import React from 'react';
import styles from './Story.module.css';
import Image from 'next/image';
import ButtonBig from 'components/Button/Button';
import FadeInWhenVisible from 'components/FadeInWhenVisible/FadeInWhenVisible';

const StorySection = () => {
  return (
    <div className={styles.section}>
      <div
        className={`${styles['container-default']} ${styles['w-container']}`}
      >
        <div className={styles['story-wrapper']}>
          <div className={`${styles['split-content']} ${styles['story-left']}`}>
            <FadeInWhenVisible>
              <div className={`${styles['image-wrapper']} ${styles['story']}`}>
                <div className={`${styles['image']} ${styles['story']}`}>
                  <Image
                    width={552}
                    height={580}
                    src={'/story/teacher.png'}
                    alt="teacher"
                  />
                </div>
              </div>
            </FadeInWhenVisible>

            <div className={styles['story-content']}>
              <h2 className={`${styles['title']} ${styles['story']}`}>
                Tuition That Inspires.
              </h2>
              <p className={`${styles['paragraph']} ${styles['story']}`}>
                Whether a student wants to understand the foundations of Maths,
                or master advanced calculus - our tutors are equipped to provide
                lessons that not only educate, but inspire a life-long love of
                learning too.
              </p>

              <ButtonBig
                className={'button-primary-header button-2-buttons w-button'}
                title="I NEED TUTORING"
                href="/student/signup"
              />
            </div>
          </div>
          <div
            className={`${styles['split-content']} ${styles['story-right']}`}
          >
            <div className={styles['mission-content']}>
              <h2 className={`${styles['title']} ${styles['mission']}`}>
                Bespoke Lessons, <br />
                Delivered Expertly.
              </h2>
              <p className={`${styles['paragraph']} ${styles['mission']}`}>
                The Traditional school environment was designed to be
                one-size-fits-all, but we know that no two students are the
                same. Our tutors deliver bespoke lessons, tailored to your
                child's specific needs, learning style and educational goals.
              </p>
              <ButtonBig
                className={'button-primary-header button-2-buttons w-button'}
                title="FIND A TUTOR"
                href="/student/signup"
              />
            </div>
            <FadeInWhenVisible>
              <div
                className={`${styles['image-wrapper']} ${styles['mission']}`}
              >
                <Image
                  loading="lazy"
                  width={552}
                  height={580}
                  src={'/story/student.png'}
                  alt="student-image"
                  className={`${styles['image']} ${styles['mission']}`}
                />
              </div>
            </FadeInWhenVisible>
          </div>
          <FadeInWhenVisible>
            <div className={styles['bg-story-wrapper']}>
              <div className={`${styles['bg']} ${styles['story-shape']}`}></div>
            </div>
          </FadeInWhenVisible>
        </div>
      </div>
    </div>
  );
};

export default StorySection;
