import React from 'react';
import styles from './NewsletterSection.module.css';
import btnStyles from '../Button/Button.module.css';
import { motion } from 'framer-motion';

function NewsletterForm() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      viewport ={{ once: true }}

      className={`${styles['newsletter-form-block']} ${styles['w-form']}`}
    >
      <form
        id="wf-form-Newsletter-Form"
        name="wf-form-Newsletter-Form"
        data-name="Newsletter Form"
        method="get"
        className={`${styles['newsletter-form']}`}
        data-wf-page-id="63cd9e4c70e278c63c37b530"
        data-wf-element-id="b73d137d-ad26-b129-ccaf-0ea0faf0a5c3"
      >
        <input
          className={`${styles.input} ${styles.newsletter} ${styles['w-input']}`}
          maxLength={256}
          name="Email"
          data-name="Email"
          placeholder="Enter your email"
          type="email"
          id="Email"
          required
        />
        <input
          type="submit"
          data-wait="Please wait..."
          className={`${btnStyles['button-primary-header']} ${btnStyles.newsletter} ${btnStyles['w-button']}`}
          value="Subscribe"
        />
      </form>
      <div
        className={`${styles['success-message']} ${styles['bg-neutral-800']} ${styles['w-form-done']}`}
      >
        <div>Thanks for joining our newsletter.</div>
      </div>
      <div className={`${styles['error-message']} ${styles['w-form-fail']}`}>
        <div>Oops! Something went wrong.</div>
      </div>
    </motion.div>
  );
}

export default NewsletterForm;
