// blogData.js
import { StaticImageData } from 'next/image';

export type IBlogPost = {
  title: string;
  url: string;
  image: string;
  date: string;
  category: string;
};

const blogPosts: IBlogPost[] = [
  {
    title: 'Find the Perfect Online Tutor: Your Path to Academic Excellence',
    url: '/blog/find-online-tutoring-guide',
    image: '/blogSection/image1.jpeg',
    date: 'September 30, 2023',
    category: 'Study',
  },
  {
    title: 'How to design a simple, yet unique and memorable brand identity',
    url: '/blog/how-to-design-a-simple-yet-unique-and-memorable-brand-identity',
    image: '/blogSection/image2.jpeg',
    date: 'October 15, 2023',
    category: 'Design',
  },
  {
    title: '5 marketing trends that you should be prepared for in 2022',
    url: '/blog/5-marketing-trends-that-you-should-be-prepared-for-in-2022',
    image: '/blogSection/image3.jpeg',
    date: 'December 5, 2023',
    category: 'Marketing',
  },
];

export { blogPosts };
