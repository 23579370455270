import React, { useRef } from 'react';
import ButtonBig from 'components/Button/Button';
import styles from './ctaSection.module.css';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';

const CtaSection = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  });
  const scale = useTransform(scrollYProgress, [0, 1], [0, 80]);
  const scaleForShape2 = useTransform(scrollYProgress, [0, 1], [0, -50]);
  const scaleForShape3 = useTransform(scrollYProgress, [0, 1], [0, -25]);

  const y = useSpring(scale);
  const y2 = useSpring(scaleForShape2);
  const y3 = useSpring(scaleForShape3);

  return (
    <div ref={ref} className={`${styles.section} ${styles.cta}`}>
      <div
        className={`${styles['container-default']} ${styles['w-container']}`}
      >
        <div className={styles['cta-wrapper']}>
          <div className={`${styles['split-content']} ${styles['cta-left']}`}>
            <h2 className={`${styles.title} ${styles.cta}`}>
              Achieve academic success with Vital Educators.
            </h2>
            <p className={`${styles.paragraph} ${styles.cta}`}>
              Our trusted tutors have helped 100's of students up their grades
              and confidence, sending them on their way to success!
            </p>
            <div className={styles['cta-left-button-wrapper']}>
              <ButtonBig
                className="button-primary-header button-white w-button"
                title="GET STARTED"
                href="/student/signup"
              />
            </div>
          </div>
          <div className={`${styles['split-content']} ${styles['cta-right']}`}>
            <div className={styles['cta-cards-left']}>
              <motion.div
                initial={{ opacity: 0, translateY: 20 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className={`${styles.card} ${styles.cta}`}
              >
                <div className={styles['card-cta-number']}>9/10</div>
                <p className={`${styles.paragraph} ${styles['card-cta']}`}>
                  Overall satisfaction score
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, translateY: 20 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                viewport={{ once: true }}
                className={`${styles.card} ${styles.cta} ${styles.last}`}
              >
                <div className={styles['card-cta-number']}>84%</div>
                <p className={`${styles.paragraph} ${styles['card-cta']}`}>
                  Target grades achieved.
                </p>
              </motion.div>
            </div>
            <div className={styles['cta-cards-right']}>
              <motion.div
                initial={{ opacity: 0, translateY: 20 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                viewport={{ once: true }}
                className={`${styles.card} ${styles.cta} ${styles.last}`}
              >
                <div className={styles['card-cta-number']}>5K+</div>
                <p className={`${styles.paragraph} ${styles['card-cta']}`}>
                  of happy students worldwide
                </p>
              </motion.div>
            </div>
            <motion.div
              style={{ y: y3 }}
              className={`${styles.bg} ${styles['cta-shape-3']}`}
            ></motion.div>
          </div>
          <motion.div
            style={{ y: y }}
            className={`${styles.bg} ${styles['cta-shape-1']}`}
          ></motion.div>
          <motion.div
            style={{ y: y2 }}
            className={`${styles.bg} ${styles['cta-shape-2']}`}
          ></motion.div>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
