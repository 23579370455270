import React, { useRef } from 'react';
import styles from './NewsletterSection.module.css';
// import btnStyles from '../Button/Button.module.css';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';
import NewsletterForm from './NewsletterForm';

const NewsletterSection = () => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 1', '1 0'],
  });
  const scaleForShape1 = useTransform(scrollYProgress, [0, 1], [-100, 100]);
  const scaleForShape2 = useTransform(scrollYProgress, [0, 1], [200, -10]);

  const yForShape1 = useSpring(scaleForShape1);
  const yForShape2 = useSpring(scaleForShape2);

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      viewport={{ once: true }}
      ref={ref}
      className={`${styles.section} ${styles.newsletter}`}
    >
      <div
        className={`${styles['container-default']} ${styles['w-container']}`}
      >
        <div className={`${styles['newsletter-wrapper']}`}>
          <motion.h2
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            viewport={{ once: true }}
            className={`${styles.title} ${styles.newsletter}`}
          >
            Subscribe to our weekly newsletter today!
          </motion.h2>

          <NewsletterForm />
          <motion.div
            style={{
              y: yForShape1,
            }}
            className={`${styles.bg} ${styles['newsletter-shape-2']}`}
          ></motion.div>
          <motion.div
            style={{
              y: yForShape2,
            }}
            className={`${styles.bg} ${styles['newsletter-shape-1']}`}
          ></motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default NewsletterSection;
